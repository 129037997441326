@use '~@emperorjack/refinery-ui/dist/styles' as *;

$instance-variable-color: #a6e22e;

#root {
  width: 100%;
  height: 100%;
}

.app {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  background: $grey-090;

  &__nav {
    @include box-shadow-1;
    z-index: 10;
  }

  &__content {
    @include scrollbar-lightest;
    display: flex;
    width: 100%;
    padding: $spacing-24 $spacing-16 0;
    align-items: center;
    flex-direction: column;
    overflow-y: scroll;
    gap: $spacing-16;
  }
}

.refinery-tab-bar {
  &__contents {
    &:hover {
      overflow-x: auto;
    }
  }
}

.refinery-card {
  text-align: center;
  margin-bottom: $spacing-16;
}

.hero-image {
  @include box-shadow-5;
  margin: $spacing-32 0;

  img {
    max-width: 1024px;
    width: 100%;
    height: 100%;
  }
}

.features {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  max-width: 1280px;
}

.feature {
  display: flex;
  flex-direction: column;
  max-width: calc(512px + #{$spacing-64});
  margin: 0 $spacing-32;
  gap: $spacing-8;

  img {
    max-width: calc(512px + #{$spacing-64});
    width: 100%;
    margin-bottom: $spacing-4;
  }

  & + & {
    margin-top: $spacing-16;
  }
}

.video {
  iframe {
    @include box-shadow-4;
    max-width: 100%;
    max-height: 56.25vw; /* ratio is 576/1024 = 0.5625 */
    margin-bottom: $spacing-8;
  }

  & + & {
    margin-top: $spacing-24;
  }
}

.text-link {
  color: $instance-variable-color;
  text-decoration: underline;

  &:hover {
    color: darken($instance-variable-color, 20%);
    cursor: pointer;
  }
}

.copyright-notice {
  padding: $spacing-8 0;
  text-align: center;
}
